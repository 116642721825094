import './App.css';
import React, { Component, Suspense, lazy, createContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import emitter from './scripts/eventEmitter';

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const Prematch = lazy(() => import('./pages/Prematch'));
const Coupon = lazy(() => import('./pages/components/Coupon'));
const MatchBox = lazy(() => import('./pages/components/MatchBox'));
const MatchDetail = lazy(() => import('./pages/MatchDetail'));
const Profile = lazy(() => import('./pages/Profile'));
const BalanceHistory = lazy(() => import('./pages/BalanceHistory'));
const Live = lazy(() => import('./pages/Live'));
const Favorites = lazy(() => import('./pages/Favorites'));
const Results = lazy(() => import('./pages/Results'));
const ResultBox = lazy(() => import('./pages/components/ResultBox'));
const CouponList = lazy(() => import('./pages/CouponList'));
const Settings = lazy(() => import('./pages/Settings'));
const Messages = lazy(() => import('./pages/Messages'));
const Language = lazy(() => import('./pages/Language'));
const Casino = lazy(() => import('./pages/Casino'));

function App() {
    const [casinoPage, setCasinoPage] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [betCount, setBetCount] = useState(0);
    const [message, setMessage] = useState(null);
    const [isUpButtonVisible, setIsUpButtonVisible] = useState(false);
    const [userBoxVisible, setUserBoxVisible] = useState(false);
    const [balanceVisible, setBalanceVisible] = useState(sessionStorage.getItem('balanceVisible') == 'true' ? true : false);
    const [selectedMenu, setSelectedMenu] = useState('home');
    const [prevSelectedMenu, setPrevSelectedMenu] = useState('home');

    const toggleBalance = () => {
        var isVisible = sessionStorage.getItem('balanceVisible');
        if (isVisible == 'true') {
            setBalanceVisible(false);
            sessionStorage.setItem('balanceVisible', false);
        } else {
            setBalanceVisible(true);
            sessionStorage.setItem('balanceVisible', true);
        }
    };

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token) {
            fetch(process.env.REACT_APP_API_URL + '/userinfo', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => response.json())
            .then(json => {
                if (json.status == 'error') {
                    setIsLoggedIn(false);
                } else {
                    setIsLoggedIn(true);
                    setUserInfo(json.user);
                }
            });
        }

        emitter.on('setBetCount', (count) => {
            setBetCount(count);
        });

        emitter.on('error', (error) => {
            setMessage({ type: 'error', message: error });
            setTimeout(() => {
                setMessage(null);
            }, 5000);
        });

        emitter.on('success', (success) => {
            setMessage({ type: 'success', message: success });
            setTimeout(() => {
                setMessage(null);
            }, 5000);
        });

        emitter.on('selectedMenu', (menu) => {
            if (menu == 'previous') {
                setSelectedMenu(prevSelectedMenu);
                return;
            }
            setPrevSelectedMenu(selectedMenu);
            setSelectedMenu(menu);
        });

        emitter.on('casinoPage', () => {
            setCasinoPage(true);
        });

        emitter.on('exitCasinoPage', () => {
            setCasinoPage(false);
        });

        const handleScroll = () => {
            if (window.scrollY > window.innerHeight / 2) {
                setIsUpButtonVisible(true);
            } else {
                setIsUpButtonVisible(false);
            }
        };
        window.addEventListener('scroll', handleScroll);

    }, []);

    if (!isLoggedIn) {
        return <Login message={message} />;
    }

    // aktif menü css
    var activeMenuClass = 'menu-active bg-lightBottomBgActive !text-lightBottomTextActive !fill-lightBottomTextActive router-link-exact-active flex flex-col w-full max-h-12 min-h-12 justify-center items-center transition-all ease-in-out duration-300 no-underline text-center hover:!text-lightBottomTextActive hover:!fill-lightBottomTextActive text-lightBottomText dark:text-darkBottomText';
    var passiveMenuClass = 'flex flex-col w-full max-h-12 min-h-12 justify-center items-center transition-all ease-in-out duration-300 no-underline text-center hover:!text-white hover:!fill-white text-lightBottomText dark:text-darkBottomText';
    if (casinoPage) {
        activeMenuClass += ' bg-darkCasinoBottomBg';
    }

    return (
        <Router>
            
            <div className="antialiased tracking-tight select-none scroll-smooth relative" onClick={() => userBoxVisible == true ? setUserBoxVisible(false) : ''}>
                <div className="absolute w-screen min-h-screen bg-lightBg dark:bg-darkBg">

                    <div className={'w-full h-12 flex justify-between align-middle pr-3 py-1 sticky top-0 z-100' + (casinoPage ? ' dark:bg-darkBottomBg bg-darkCasinoBottomBg' : ' bg-lightTopBg')} is-history-visible="false">
                        <div className="w-2/10 flex items-center justify-start">
                            <BackButton />
                        </div>
                        <div className="w-6/10 text-center flex flex-col justify-center items-center text-white">
                            <Link to="/home" className="router-link-active router-link-exact-active" aria-current="page">
                                <img src="/images/logo.png" className="h-auto! w-logo" alt="logo" />
                            </Link>
                        </div>
                        <div className="w-2/10 flex justify-end gap-4 items-center">
                            <div>
                                <div className="flex items-cener justify-center h-7 w-7">
                                    <img className="w-7" src="/images/livechat.svg" alt="Live Chat" />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <Link onClick={(e) => { setUserBoxVisible(true) }} className="ant-dropdown-link flex flex-col justify-center items-center relative ant-dropdown-trigger">
                                    <img className="h-5" src="/images/user.svg" alt="user" />
                                    <div className="text-white text-md font-semibold">
                                        {balanceVisible ? parseFloat(userInfo.balance).toFixed(2) : 'Hesabım'}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {message ?
                        (message.type == 'error' ?
                            <div className="flex flex-col fixed top-0 right-0 p-4 z-200 w-screen">
                                <div className="flex flex-col p-3 mb-2 text-gray-500 rounded-lg drop-shadow-xl backdrop-blur-lg backdrop-grayscale bg-black/50" role="alert">
                                    <div className="flex items-center">
                                        <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 rounded-lg text-xl bg-red-500">
                                            <div className="i-tabler-x text-white"></div>
                                        </div>
                                        <p className="ml-3 mb-0 text-md font-normal text-white">{message.message}</p>
                                    </div>
                                </div>
                            </div>
                        :
                            <div className="flex flex-col fixed top-0 right-0 p-4 z-200 w-screen">
                                <div className="flex flex-col p-3 mb-2 text-gray-500 rounded-lg drop-shadow-xl backdrop-blur-lg backdrop-grayscale bg-black/50" role="alert">
                                    <div className="flex items-center">
                                        <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 rounded-lg text-xl bg-green-500">
                                            <div className="i-tabler-check text-white"></div>
                                        </div>
                                        <p className="ml-3 mb-0 text-md font-normal text-white">{message.message}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    : ''}

                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/home" element={<Home />} />
                            <Route path="/prematch-menu/0" element={<Prematch />} />
                            <Route path="/prematch-menu/0/:sport_id" element={<MatchBox showLeague={true} />} />
                            <Route path="/prematch-menu/3" element={<Prematch date="24hours" />} />
                            <Route path="/prematch-menu/2" element={<Prematch date="1hours" />} />
                            <Route path="/prematch-menu/6" element={<Prematch date="3hours" />} />
                            <Route path="/favorite-tournament/:sport_id/tournament/:tournament_id" element={<MatchBox showSports={false} showLeague={true} />} />
                            <Route path="/match-detail/:match_id" element={<MatchDetail />} />
                            <Route path="/match-detail/live/:match_id" element={<MatchDetail type="live" />} />
                            <Route path="/account/profile" element={<Profile user={userInfo} />} />
                            <Route path="/account/profile/change-password" element={<Profile user={userInfo} page="parolam" />} />
                            <Route path="/account/finance/balance-history" element={<BalanceHistory />} />
                            <Route path="/account/settings" element={<Settings user={userInfo} />} />
                            <Route path="/account/messages" element={<Messages />} />
                            <Route path="/account/language" element={<Language />} />
                            <Route path="/live" element={<Live />} />
                            <Route path="/my-favorites" element={<Favorites />} />
                            <Route path="/results" element={<Results />} />
                            <Route path="/results/:sport_id" element={<Results />} />
                            <Route path="/results/:sport_id/:country_id" element={<Results />} />
                            <Route path="/results/:sport_id/:country_id/:league_id" element={<ResultBox />} />
                            <Route path="/tickets" element={<CouponList />} />
                            <Route path="/casino" element={<Casino />} />
                            <Route path="/live-casino" element={<Casino />} />
                       </Routes>
                    </Suspense>

                    <div className={'alt-menu w-screen max-h-12 flex fixed items-center bottom-0 z-91 left-0 justify-evenly transition-all ease-in-out duration-200 z-30 shadow-[0_0_0.75rem_rgba(0,0,0,0.3)] dark:bg-darkBottomBg' + (casinoPage ? ' bg-darkCasinoBottomBg' : ' bg-lightBottomBg')}>
                        <Link to="/home" onClick={() => emitter.emit('closeCoupon')} className={selectedMenu == 'home' ? activeMenuClass : passiveMenuClass} aria-current="page">
                            <div className="flex min-h-6 max-h-6 w-full items-center justify-center">
                                <div className="i-tabler-home w-23px h-23px fill-lightBottomText dark:fill-darkBottomText"></div>
                            </div>
                            <span className="font-normal text-md">Ana Sayfa</span>
                        </Link>
                        <Link to="/live" onClick={() => emitter.emit('closeCoupon')} className={selectedMenu == 'live' ? activeMenuClass : passiveMenuClass}>
                            <div className="flex min-h-6 max-h-6 w-full items-center justify-center fill-lightBottomText dark:fill-darkBottomText">
                                <img src="/images/live.svg" />
                            </div>
                            <span className="font-normal text-md">Canlı</span>
                        </Link>
                        <Link to="/casino" onClick={() => emitter.emit('closeCoupon')} className={selectedMenu == 'casino' ? activeMenuClass : passiveMenuClass}>
                            <div className="flex min-h-6 max-h-6 w-full items-center justify-center">
                                <img src="/images/casino.svg" />
                            </div>
                            <span className="font-normal text-md">Slot</span>
                        </Link>
                        <Link to="/tickets" onClick={() => emitter.emit('closeCoupon')} className={selectedMenu == 'tickets' ? activeMenuClass : passiveMenuClass}>
                            <span className="ant-badge">
                                <div className="flex min-h-6 max-h-6 w-full items-center justify-center">
                                    <img src="/images/kupon.svg" />
                                </div>
                                <sup data-show="false" className="ant-scroll-number ant-badge-count" title="0" style={{ display: 'none' }}>0</sup>
                            </span>
                            <span className="font-normal text-md">Bahislerim</span>
                        </Link>
                        <div onClick={() => emitter.emit('openCoupon') } className={selectedMenu == 'coupon' ? activeMenuClass : passiveMenuClass}>
                            <span className="ant-badge">
                                <div className="flex flex-col justify-center items-center">
                                    <div className="flex min-h-6 max-h-6 w-full items-center justify-center text-lightBottomText fill-lightBottomText dark:text-darkBottomText dark:fill-lightBottomText">
                                        <img src="/images/kuponum.svg" />
                                    </div>
                                </div>
                                <sup data-show="false" className="ant-scroll-number ant-badge-count" title="1" style={{ display: betCount ? 'block' : 'none' }}>
                                    <span className="ant-scroll-number-only" style={{ transition: 'none' }}>
                                        <p className="ant-scroll-number-only-unit current">{betCount}</p>
                                    </span>
                                </sup>
                            </span>
                            <span className="font-normal text-md">Kupon</span>
                        </div>
                    </div>
                    
                    <Coupon />

                </div>
            </div>
            {isUpButtonVisible ? 
            <div className="flex flex-col items-center fixed left-4 bottom-20 z-89" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <div className="flex drop-shadow-md items-center justify-center w-11 h-11 bg-black bg-opacity-60 rounded-full mt-2">
                    <div className="w-8 h-8 bg-gray-400 i-tabler-chevron-up"></div>
                </div>
            </div>
            : ''}
            {userBoxVisible && userInfo ? 
            <UserBox 
                user={userInfo} 
                setUserBoxVisible={setUserBoxVisible}
                toggleBalance={toggleBalance}
                balanceVisible={balanceVisible}
            /> : ''}
        </Router>
    );
}

function BackButton() {
    const [isBackButtonVisible, setIsBackButtonVisible] = useState(false);
    let navigate = useNavigate();
    let location = useLocation();

    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        console.log(location.key);
        if (location.key != 'default') {
            setIsBackButtonVisible(true);
        } else {
            setIsBackButtonVisible(false);
        }
    });

    if (!isBackButtonVisible) {
        return '';
    }

    return (
        <div className="w-full mx-1" onClick={handleGoBack}>
            <div className="bg-white w-7 h-7 i-tabler-chevron-left"></div>
        </div>
    );
}

function convertToAsterisks(text) {
    return String(text).split('').map(() => '*').join('');
  }

function UserBox(params) {
    return (
        <div style={{ position: 'absolue', top: 0, left: 0, width: '100%' }}>
            <div>
                <div className="ant-dropdown ant-dropdown-placement-bottomRight" style={{ minWidth: 29, left: 80, top: 46 }}>
                    <div className="ant-dropdown-content">
                        <div className="flex mt-1 -mr-2 rounded-t-md shadow border border-neutral-300 dark:border-gray-900" mode="vertical" selectable="false" expandicon="function(){return I}" prefixcls="ant-dropdown-menu" getpopupcontainer="function(){return s.value.getPopupDomNode()}">
                            <ul className="ant-menu ant-menu-root ant-menu-vertical ant-menu-light rounded-t-md" role="menu" data-menu-list="true">
                                <div className="flex flex-col w-80 rounded-t-md font-semibold">
                                    <div className="flex flex-col font-semibold rounded-t-md py-3 px-5.5 bg-white text-lightThree border-neutral-300 dark:bg-darkTopMenuBgOne dark:text-white dark:rounded-t-md">
                                        <div className="flex flex-row pb-2 h-10 border-b border-neutral-300 items-center justify-center uppercase border-neutral-300 dark:border-neutral-600">
                                            <span>{params.user.username}</span>
                                        </div>
                                        <div className="flex flex-row h-10 text-lg items-center pt-2">
                                            <div className="w-6/12 capitalize">Hesabım</div>
                                            <div className="flex w-full flex-row justify-end gap-2" onClick={() => params.toggleBalance()}>
                                                <div className="bg-stone-700 w-5 h-5 i-tabler-eye"></div>
                                                {params.balanceVisible ? parseInt(params.user.balance).toFixed(2) : convertToAsterisks(params.user.balance)} TRY
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-wrap font-semibold px-5.5 py-3 bg-lightTopMenuBgTwo text-lightThree border-neutral-300 dark:bg-darkTopMenuBgTwo dark:text-white dark:border-[#dedede]">
                                        <Link to="/account/profile" onClick={() => params.setUserBoxVisible(false)} className="flex items-center h-10 text-lightThree dark:text-white">Hesap Bilgileri</Link>
                                        <Link to="/account/finance/balance-history" onClick={() => params.setUserBoxVisible(false)} className="flex items-center h-10 text-lightThree dark:text-white">Hesap Özeti</Link>
                                        <Link to="/tickets" onClick={() => params.setUserBoxVisible(false)} className="flex items-center h-10 text-lightThree dark:text-white">Bahislerim</Link>
                                        <Link to="/account/settings" onClick={() => params.setUserBoxVisible(false)} className="flex items-center h-10 text-lightThree dark:text-white">Ayarlar</Link>
                                        <Link to="/account/messages" onClick={() => params.setUserBoxVisible(false)} className="flex justify-between items-center h-10 text-black dark:text-white">Mesajlar </Link>
                                    </div>
                                    <div onClick={() => logout()} className="flex flex-col flex-wrap font-semibold border-t px-5.5 py-1.5 bg-lightTopMenuBgTwo text-lightThree border-neutral-300 dark:bg-darkTopMenuBgTwo dark:text-white dark:border-[#dedede]">
                                        <div className="flex items-center w-6/12 h-10">Çıkış Yap</div>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function logout() {
    sessionStorage.clear();
    window.location.reload();
}

export default App;
